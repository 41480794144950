import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import { RenegotiationContractApiType } from 'src/app/v2/shared/types/renegotiation.type';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  private readonly GTM_SHOW_LOG = 'gtmShowLog';
  private readonly GTM_FIRST_LOGIN = 'gtmFirstLogin';

  private pingHome(obj: any) {
    const tmp = {...obj};
    delete tmp['expirationTime'];
    if (localStorage.getItem(this.GTM_SHOW_LOG)) {
      console.log(JSON.stringify(tmp, null, 4));
    }
    if (tmp && this.window?.dataLayer?.push) {
      this.window.dataLayer.push(tmp);
    }
  }

  logEvent(hit: any) {
    try {
      this.pingHome(hit);
    } catch (e) {}
  }

  logEventWithKey(hit: any, key: string) {
    try {
      if (key) {
        let item = JSON.parse(sessionStorage.getItem(this.GTM_FIRST_LOGIN + key) || '{}');
        if(item && item['expirationTime'] > new Date().getTime()) {
          return;
        }
      }
      hit['expirationTime'] = new Date().getTime() + 1000 * 60 * 60 * 12;
      this.pingHome(hit);
      if (key) {
        sessionStorage.setItem(this.GTM_FIRST_LOGIN + key, JSON.stringify(hit));
      }
    } catch (e) {}
  }

  logLoginEvent(contracts: any, preferred_username: string) {
    try {
      let item = JSON.parse(sessionStorage.getItem(this.GTM_FIRST_LOGIN + preferred_username) || '{}');
      if(item && item['expirationTime'] > new Date().getTime()) {
        return;
      }
      let user_omni_type = '';
      let product_name = '';
      let company_name = '';
      let debt_amount = '';
      let installments = '';
      let contract_status = '';
      const productNameSet: any = {};
      const companySet: any = {};
      const userOmniTypeSet: any = {};
      const debtAmountSet: any = {};
      const installmentsSet: any = {};
      const contractStatusSet: any = {};
      for (const contract of contracts) {
        productNameSet[this.retrieveProductName(contract)] = true;
        companySet[contract.product.assignor] = true;
        userOmniTypeSet[contract.offer ? 'com-oferta' : 'sem-oferta'] = true;
        debtAmountSet[this.formatNumber(contract.debit?.correctedValue)] = true;
        if (contract.debit?.installmentsContractDept) installmentsSet[contract.debit?.installmentsContractDept?.length + ''] = true;
        else installmentsSet['0'] = true;
        contractStatusSet[this.identifyStatus(contract)] = true;
      }
      product_name = Object.keys(productNameSet).join(';');
      company_name = Object.keys(companySet).join(';');
      user_omni_type = Object.keys(userOmniTypeSet).join(';');
      debt_amount = Object.keys(debtAmountSet).join(';');
      installments = Object.keys(installmentsSet).join(';');
      contract_status = Object.keys(contractStatusSet).join(';');
      //GTM:ID0-2
      const hit = {
        event: 'user_logged',
        user_omni_type,
        product_name,
        company_name,
        debt_amount,
        installments,
        contract_status,
        expirationTime: new Date().getTime() + 1000 * 60 * 60 * 12
      };
      sessionStorage.setItem(this.GTM_FIRST_LOGIN + preferred_username, JSON.stringify(hit));
      this.pingHome(hit);
    } catch (e) {
      console.error('Error on logLoginEvent', e);
    }
  }

  identifyStatus(contract: RenegotiationContractApiType) {
    const currentAgreement = contract.agreements?.find(
      agreement => agreement.status === 'PENDING' || agreement.status === 'ACTIVE' || agreement.status === 'ACCEPTED',
    );
  
    switch (currentAgreement?.status) {
      case 'PENDING':
        return 'em-analise';
      case 'ACTIVE':
      case 'ACCEPTED': {
        const nextInstallment = currentAgreement.installments?.find(installment => !installment.paymentDate);
        const isPastDue = nextInstallment?.expirationDate
          && new Date(nextInstallment.expirationDate).getTime() + (24 * 60 * 60 * 1000) < new Date().getTime();
        return isPastDue ? 'atrasado' : 'renegociado';
      }
      default:
        return 'divida';
    }
  }

  retrieveProductName(contract: any) {
    return contract.product.category +
      ((contract.product.detail != null) ? ' - ' + this.capitalizeFirstLetter(contract.product.detail) : '');
  }

  logLogoutEvent(preferred_username: string): Observable<any> {
    return new Observable(observer => {
      try {
        //GTM:ID0-3
        let hit = JSON.parse(localStorage.getItem(this.GTM_FIRST_LOGIN + preferred_username) || '{}');
        hit['event'] = 'user_logout';
        this.pingHome(hit);
        observer.next(hit);
        observer.complete();
      } catch (e) {
        console.error('Error on logLogoutEvent', e);
        observer.error(e);
      }
    });
  }


  private capitalizeFirstLetter(text: string) {
    let result: any;
    result = text.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
    return result;
  }

  formatNumber(n: any): number {
    if (Number(n) === n) {
      return Math.round(n*100)/100;
    }
    return 0;
  }

  getCurrentUrl() {
    return window.location.href
  }

  // .. add more custom methods as needed by your app.
}
