import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaymentType } from '../shared/types/renegotiation.type';

export class MarkContractAsDisplayed {
  static readonly type = '[ContractDisplayed] Mark Contract as Displayed';
  constructor(public contractId: string) { }
}

export class SetPaymentType {
  static readonly type = '[PaymentType] Set Payment Type';
  constructor(public payload: PaymentType) { }
}

export interface SharedStateModel {
  displayedContracts: string[];
  paymentType: PaymentType[];
}

@State<SharedStateModel>({
  name: 'sharedState',
  defaults: {
    displayedContracts: [],
    paymentType: [],
  }
})
@Injectable()
export class SharedState {

  @Selector()
  static getDisplayedContracts(state: SharedStateModel) {
    return state.displayedContracts;
  }

  @Selector()
  static getPaymentType(state: SharedStateModel) {
    return state.paymentType;
  }

  @Action(MarkContractAsDisplayed)
  markContractAsDisplayed(ctx: StateContext<SharedStateModel>, action: MarkContractAsDisplayed) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      displayedContracts: [...state.displayedContracts, action.contractId]
    });
  }

  @Action(SetPaymentType)
  setPaymentType(ctx: StateContext<SharedStateModel>, action: SetPaymentType) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      paymentType: [...state.paymentType, action.payload]
    });
  }
}
