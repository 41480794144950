import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private oAuthService: OAuthService,
              private router: Router,) { }
  isLoggedOn(): boolean {
    const accessToken = localStorage.getItem('access_token');
    return !!accessToken;
  }

  logout() {
    localStorage.removeItem('access_token');
    sessionStorage.clear();
    this.oAuthService.logOut();
    this.router.navigate(['/']);
  }
}
